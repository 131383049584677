import * as types from "../constants/ActionTypes";

// export const requestCreateBlockOptions = () => ({
//   type: types.REQUEST_CREATE_BLOCK_OPTIONS
// });
//
// export const getCreateBlockOptions = (options = []) => ({
//   type: types.GET_CREATE_BLOCK_OPTIONS,
//   options
// });

export const requestOrders = () => ({
  type: types.REQUEST_ORDERS
});
export const syncOrders = (playerId ) => ({
  type: types.SYNC_ORDERS,
  playerId
});

export const getOrders = (list = []) => ({
  type: types.GET_ORDERS,
  list
});

export const requestPublicProfile = (playerId) => ({
  type: types.REQUEST_USER,
  playerId
})

export const getPublicProfile = (user = null) => ({
  type: types.GET_USER,
  user
})

export const requestWallets = (playerId) => ({
  type: types.REQUEST_WALLETS,
  playerId
})

export const getWallets = (wallets = null) => ({
  type: types.GET_WALLETS,
  wallets
})

export const requestPayoutProfile = (playerId) => ({
  type: types.REQUEST_PAYOUT_PROFILE,
  playerId
})

export const getPayoutProfile = (payoutProfile = null) => ({
  type: types.GET_PAYOUT_PROFILE,
  payoutProfile
})

// export const requestGames = () => ({
//   type: types.REQUEST_GAMES
// });
//
// export const getGames = (games = []) => ({
//   type: types.GET_GAMES,
//   games
// });
//
// export const requestSchedule = () => ({
//   type: types.REQUEST_SCHEDULE
// });
//
// export const getSchedule = (scheduleList = []) => ({
//   type: types.GET_SCHEDULE,
//   scheduleList
// });

export const requestRooms = () => ({
  type: types.REQUEST_ROOMS
});

export const getRooms = (rooms = []) => ({
  type: types.GET_ROOMS,
  rooms
});

// export const requestTemplates = () => ({
//   type: types.REQUEST_TEMPLATES
// });
//
// export const getTemplates = (templates = []) => ({
//   type: types.GET_TEMPLATES,
//   templates
// });
