import * as React from "react";
import { Header as H, Form } from "tabler-react";
import Input from "react-moment-input";
import moment from "moment";
import { connect } from "react-redux";
import Home from "../../components/home";
import OrderRows from "./orderRow";
import Create from "../../assets/fab.png";
import { bindActionCreators } from "redux";
import {
  requestOrders,
  requestPublicProfile,
  syncOrders,
  requestWallets,
  requestPayoutProfile
} from "../../actions/formActions";
//import { deleteBlocks } from "../../actions/deleteActions";
import Pagination from "../../components/pagination";
import { WinningsDistributionFunction } from "../../rpc/service/pb";

interface Props {
  orderData: any;
  router: any;
  requestOrders: any;
  syncOrders: any;
  history: any;
}

class OrderList extends React.Component<Props> {
  state = {
    dateCreated: moment(),
    orders: [],
    Filters: {
      duration: "all",
      entryFee: "all",
      distribution: "all",
      sortBy: "all",
      createdBy: "all"
    },
    newTemp: [],
    currentOrders: [],
    currentPage: null,
    totalPages: null,
    playerId: ""
  };

  fetchData = async () => {
    await this.props.requestOrders();
  };

  handlePlayerId = async (event) => {
    if(this.state.playerId == ""){
      //this.fetchData();
    }
    else{
      await this.props.syncOrders(this.state.playerId);
      await this.props.requestPublicProfile(this.state.playerId);
      await this.props.requestWallets(this.state.playerId);
      await this.props.requestPayoutProfile(this.state.playerId);
    }

  }

  componentDidMount = async () => {
    //await this.fetchData();
  };

  onPageChanged = async data => {
    // console.log(data, "-----data-------");
    const { orders } = await this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentOrders = orders.slice(offset, offset + pageLimit);

    this.setState(
      { currentPage, currentOrders, totalPages }
      // , () =>
      // console.log(this.state.currentBlocks, "curntblock")
    );
  };

  componentDidUpdate = async (prevProps, prevState) => {
    //console.log("prevProps", prevProps, prevState)
    //if (prevProps.orderData != this.props.orderData) {
      if(false){
      //console.log("Missmatch found", prevProps, prevState)
      this.setState(
        { orders: this.props.orderData, newTemp: this.props.orderData }
        // , () =>
        // console.log(this.state.blocks, "blocks in did update")
      );
      let createdByList = this.props.orderData.map(f => f.CreatedBy);
      // console.log([...new Set(createdByList)]);
      // createdByList = [...new Set(createdByList)];
      // this.setState({ createdByList });
      // let data = {
      //   currentPage: 1,
      //   pageLimit: 200,
      //   totalPages: Math.ceil(this.state.orders.length / 200),
      //   totalRecords: this.state.orders.length
      // };
      // this.onPageChanged(data);
      this.applyFilter(this.state.Filters);
    }
  };

  edit = obj => {
    console.log(obj);
    //this.props.history.push("/block/edit", obj);
  };

  handleChange = event => {
    let Filters = { ...this.state.Filters };
    let name = event.target.name;
    // console.log(name, event.target.value);
    Filters[name] = event.target.value;
    this.setState({ ...this.state.Filters, Filters }, () =>
      this.applyFilter(this.state.Filters)
    );
  };

  entryFeeFilter = (entryFee, newTemp) => {
    let temp =
      entryFee !== "all" && entryFee !== undefined
        ? newTemp.filter(f => {
            return f.EntryFeeValue == entryFee;
          })
        : newTemp;
    this.setOrders(temp);

    return temp;
  };

  distributionFilter = (dist, newTemp) => {
    // console.log(dist);
    let temp =
      dist !== "all" && dist !== undefined
        ? newTemp.filter(f => {
            return f.Wdf == dist;
          })
        : newTemp;
    this.setOrders(temp);

    return temp;
  };

  sortFilter = (filter, newTemp) => {
    let temp;
    // console.log(filter);
    switch (filter) {
      case "entryFee":
        temp = newTemp.sort((a, b) => a.EntryFeeValue - b.EntryFeeValue);
        break;
      case "duration":
        temp = newTemp.sort((a, b) => a.DurationMin - b.DurationMin);
        break;
      case "distribution":
        temp = newTemp.sort(
          (a, b) => a.PercentageWinners - b.PercentageWinners
        );
        break;
      case "createdBy":
        temp = newTemp.sort((a, b) => {
          let textA = a.CreatedBy.toUpperCase();
          let textB = b.CreatedBy.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        break;
      default:
        temp = newTemp;
    }
    this.setOrders(temp);

    return temp;
  };

  createdByFilter = (f, newTemp) => {
    let temp =
      f !== "all" && f !== undefined
        ? newTemp.filter(e => {
            return e.CreatedBy == f;
          })
        : newTemp;
    this.setOrders(temp);
    return temp;
  };

  applyFilter = async f => {
    const { newTemp } = this.state;

    let a = newTemp;
    let b = await this.durationFilter(f.duration, a);

    let c = await this.entryFeeFilter(f.entryFee, b);
    let d = await this.distributionFilter(f.distribution, c);
    let e = await this.createdByFilter(f.createdBy, d);

    await this.sortFilter(f.sortBy, e);
  };

  durationFilter = (duration, newTemp) => {
    // const { newTemp } = this.state;
    // console.log(newTemp, "newtemp in duration");
    let temp;

    switch (duration) {
      case "120":
        temp = newTemp.filter(e => {
          return e.DurationMin < duration;
        });
        break;
      case "240":
        temp = newTemp.filter(e => {
          return e.DurationMin < duration && e.DurationMin >= 120;
        });
        break;
      case "360":
        temp = newTemp.filter(e => {
          return e.DurationMin < duration && e.DurationMin >= 240;
        });
        break;
      case "720":
        temp = newTemp.filter(e => {
          return e.DurationMin < duration && e.DurationMin >= 360;
        });
        break;
      case "1440":
        temp = newTemp.filter(e => {
          return e.DurationMin < duration && e.DurationMin >= 720;
        });
        break;
      default:
        // console.log(newTemp, "newtemp in defaullt");
        temp = newTemp;
    }

    this.setOrders(temp);

    return temp;
  };

  setOrders = temp => {
    let data;
    this.setState(
      {
        orders: temp
      },
      () => {
        // console.log(this.state.blocks);
        data = {
          currentPage: 1,
          pageLimit: 15,
          totalPages: Math.ceil(this.state.orders.length / 15),
          totalRecords: this.state.orders.length
        };
        this.onPageChanged(data);
      }
    );
  };

  handleVariable = e => {
    let a = e.target.name;
    this.setState({ [a]: e.target.value }, () => console.log(this.state));
  };
  handleDate = event => {
    console.log(event);
    this.setState({ dateCreated: event }, () => {
      console.log(event.toDate());
      //   this.setStep(diff);
    });
  };


  render() {
    return (
      <Home>
        {
          this.props.user && this.props.wallets && this.props.payoutProfile?
          <div className="filters" style={{display: 'flex', justifyContent: 'space-around'}}>
            <div>
            <img src={"https://res.cloudinary.com/getmega"+ this.props.user.PhotoURL.substr(0,16)+"c_fill,g_auto,h_100,w_100/"+this.props.user.PhotoURL.substr(16)}/>
            <h4>{this.props.user.DisplayName}</h4>
            </div>
            <div style={{display: 'flex'}}>
            {
              this.props.wallets.map((wallet)=>{
                return(
                <div style={{padding: '45px'}}>
                <h4>{wallet.Title}</h4>
                <h4>{wallet.Balance}</h4>
                </div>
              )
              })
            }
            </div>
            <div style={{display: 'flex'}}>
              <div style={{padding: '45px'}}>
                <h4>Withdrawal Limit</h4>
                <h4>{this.props.payoutProfile.MinCashoutAllowed}</h4>
              </div>
            </div>
          </div>
          :
          <div className="filters">
            <h4>Enter the PlayerID</h4>
          </div>
        }

        <div className="filters">
        <Form.Input
          name="playerid-input"
          placeholder="Player ID"
          value = {this.state.playerId}
          onChange = {(event)=>{this.setState({playerId: event.target.value})}}
          />
          {
            this.state.playerId == "" ?
            null :
            <button onClick={this.handlePlayerId}>Go</button>
          }
        </div>
        <hr />
        <div className="table-schedule">
          <OrderRows
            data={this.props.orderData}
          />
        </div>
        {this.state.orders.length && (
          <Pagination
            totalRecords={this.state.orders.length}
            pageLimit={15}
            pageNeighbours={0}
            onPageChanged={this.onPageChanged}
          />
        )}
      </Home>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    orderData: state.orderState.orders,
    router: state.router,
    user: state.orderState.user,
    wallets: state.orderState.wallets,
    payoutProfile: state.orderState.payoutProfile
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      requestOrders,
      syncOrders,
      requestPublicProfile,
      requestWallets,
      requestPayoutProfile
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderList);
