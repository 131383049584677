import * as types from "../constants/ActionTypes";

const initialState = {
    data: null,
    responseStatus:0
};

export default (state = initialState, action) => {
    console.log("111111111111111111111inside kycdocdata reducer111111111111111111111")
    console.log("action is :",action)
  switch (action.type) {
    
    case types.GET_KYC_PAN_DATA:
        console.log("inside case stmnt")
      return {
        ...state,
        data: {...action.resData.data},
        responseStatus:action.resData.responseStatus,
      
        
      };
    case types.GET_KYC_PAN_DATA_FAILURE:
        console.log("failure");
        return {
            data:null,
            responseStatus:action.responseStatus,
           
        }
    case types.FETCHING_PAN_DOC:
      console.log("fetching kyc pan red")
        return {
            data: null,
            responseStatus:0
        }
    
    default:
      return state;
  }
};
