import * as React from "react";
import { Dialog } from "@blueprintjs/core";
import { Table } from "tabler-react";
import { connect } from "react-redux";

import moment from "moment";

interface DialogState {
  autoFocus: boolean;
  canEscapeKeyClose: boolean;
  canOutsideClickClose: boolean;
  enforceFocus: boolean;
  isOpen: boolean;
  usePortal: boolean;
}

interface DialogProps {
  isOpen: boolean;
  handleClose(): void;
  orderId: any;
}

class TransactionsDialog extends React.Component<DialogProps, DialogState> {
  public state: DialogState = {
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    isOpen: false,
    usePortal: false
  };
  render() {
    const { orderId, orderMap } = this.props;
    // console.log(data, "data");
    var orderDetail = orderMap[orderId];
    var payments = orderDetail.Payments;
    var fullfillments = orderDetail.Fulfillments;
    var refunds = orderDetail.Refunds;
    return (
      <Dialog
        className="schedule-dialog"
        icon="info-sign"
        onClose={this.props.handleClose}
        title={"Order ID : "+this.props.orderId}
        {...this.state}
        isOpen={this.props.isOpen}
      >
      {
        payments && payments.length > 0 ?
        <div>
        <h2>Payments</h2>
        <Table>
          <Table.Header>
            <Table.ColHeader>Title</Table.ColHeader>
            <Table.ColHeader>Amount</Table.ColHeader>
            <Table.ColHeader>State</Table.ColHeader>
            <Table.ColHeader>Source</Table.ColHeader>
            <Table.ColHeader>Destination</Table.ColHeader>
            <Table.ColHeader>CreatedAt</Table.ColHeader>
          </Table.Header>
          <Table.Body>
          {
            payments && payments.length > 0 ?
            payments.map((pay, i) => (
              <Table.Row key={i}>
                <Table.Col>{pay.Title}</Table.Col>
                <Table.Col>{pay.Amount} {pay.Currency}</Table.Col>
                <Table.Col>{pay.State}</Table.Col>
                <Table.Col>{pay.Source}</Table.Col>
                <Table.Col>{pay.Destination}</Table.Col>
                <Table.Col>{moment.unix(pay.CreatedAt.seconds).format("dddd, MMMM Do YYYY, h:mm:ss a")}</Table.Col>
              </Table.Row>
            ))
            :
            <Table.Row><Table.Col>{"No payments"}</Table.Col></Table.Row>
          }
          </Table.Body>
        </Table>
        </div>
        :
        <h2>No Payments</h2>

      }

      {
        fullfillments && fullfillments.length > 0 ?
        <div>
        <h2>Fullfillments</h2>
        <Table>
          <Table.Header>
            <Table.ColHeader>Type</Table.ColHeader>
            <Table.ColHeader>Title</Table.ColHeader>
            <Table.ColHeader>Amount</Table.ColHeader>
            <Table.ColHeader>State</Table.ColHeader>
            <Table.ColHeader>Source</Table.ColHeader>
            <Table.ColHeader>Destination</Table.ColHeader>
            <Table.ColHeader>CreatedAt</Table.ColHeader>
          </Table.Header>
          <Table.Body>
          {
            fullfillments && fullfillments.length > 0 ?
            fullfillments.map((pay, i) => {
                if(pay.Type == "Payment") {return (
                <Table.Row key={i}>
                  <Table.Col>{pay.Type}</Table.Col>
                  <Table.Col>{pay.Payment.Title}</Table.Col>
                  <Table.Col>{pay.Payment.Amount} {pay.Payment.Currency}</Table.Col>
                  <Table.Col>{pay.Payment.State}</Table.Col>
                  <Table.Col>{pay.Payment.Source}</Table.Col>
                  <Table.Col>{pay.Payment.Destination}</Table.Col>
                  <Table.Col>{moment.unix(pay.Payment.CreatedAt.seconds).format("dddd, MMMM Do YYYY, h:mm:ss a")}</Table.Col>
                </Table.Row>);}

                else if(pay.Type == "Payout") { return(
                <Table.Row key={i}>
                  <Table.Col>{pay.Type}</Table.Col>
                  <Table.Col>{pay.Payout.Title}</Table.Col>
                  <Table.Col>{pay.Payout.Amount} {pay.Payout.Currency}</Table.Col>
                  <Table.Col>{pay.Payout.State}</Table.Col>
                  <Table.Col>{pay.Payout.Source}</Table.Col>
                  <Table.Col>{pay.Payout.Destination}</Table.Col>
                  <Table.Col>{moment.unix(pay.Payout.CreatedAt.seconds).format("dddd, MMMM Do YYYY, h:mm:ss a")}</Table.Col>
                </Table.Row>
              );
            }
            }
          )
            :
            <Table.Row><Table.Col>{"No Fullfillments"}</Table.Col></Table.Row>
          }
          </Table.Body>
        </Table>
        </div>
        :
        <h2>No Fullfillments</h2>
      }

      {
        refunds && refunds.length > 0 ?
        <div>
          <h2>Refunds</h2>
          <Table>
            <Table.Header>
              <Table.ColHeader>Title</Table.ColHeader>
              <Table.ColHeader>Amount</Table.ColHeader>
              <Table.ColHeader>State</Table.ColHeader>
              <Table.ColHeader>Source</Table.ColHeader>
              <Table.ColHeader>Destination</Table.ColHeader>
              <Table.ColHeader>CreatedAt</Table.ColHeader>
            </Table.Header>
            <Table.Body>
            {
              refunds && refunds.length > 0 ?
              refunds.map((pay, i) => (
                <Table.Row key={i}>
                  <Table.Col>{pay.Title}</Table.Col>
                  <Table.Col>{pay.Amount} {pay.Currency}</Table.Col>
                  <Table.Col>{pay.State}</Table.Col>
                  <Table.Col>{pay.Source}</Table.Col>
                  <Table.Col>{pay.Destination}</Table.Col>
                  <Table.Col>{moment.unix(pay.CreatedAt.seconds).format("dddd, MMMM Do YYYY, h:mm:ss a")}</Table.Col>
                </Table.Row>
              ))
              :
              <Table.Row><Table.Col>{"No Refunds"}</Table.Col></Table.Row>
            }
            </Table.Body>
          </Table>
        </div>
        :
        <h2>No Refunds</h2>
      }

      </Dialog>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    orderMap: state.orderState.map,
    router: state.router
  };
};

export default connect(
  mapStateToProps
)(TransactionsDialog);
