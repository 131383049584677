import * as types from "../constants/ActionTypes";

const initialState = {
  orders: [],
  rooms: [],
  user: null,
  isReady: false,
  payoutProfile: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_ORDERS:
      return {
        ...state,
        orders: [],
        isReady: false
      };
      case types.SYNC_ORDERS:
        return {
          ...state,
          orders: [],
          map: {},
          isReady: false
        };
    case types.REQUEST_ROOMS:
      return {
        ...state,
        rooms: [],
        isReady: false
      };
    case types.GET_ORDERS:
      console.log("get orders")
      return {
        ...state,
        orders: action.list,
        map: action.map,
        isReady: true
      };
    case types.GET_ROOMS:
      return {
        ...state,
        rooms: action.rooms,
        isReady: true
      };
    case types.GET_USER:
      return {
        ...state,
        user: action.user
      }
    case types.GET_WALLETS:
      return {
        ...state,
        wallets: action.list
      }
    case types.GET_PAYOUT_PROFILE:
      return {
        ...state,
        payoutProfile: action.payoutProfile
      }
    default:
      return state;
  }
};
