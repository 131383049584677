import * as React from "react";
import { Switch, Route } from "react-router-dom";
// import App from "./app";
import Login from "../components/login";
// import Studio from "../components/studio";
// import Graph from "../components/graph";
import AuthProvider from "../Contexts/AuthContext";
import ProtectedRoute from "./protectedRoute";
import Header from "../components/header";
// import HaberDasher from "../components/haberdasher";
// import SchedulerCalendar from "../containers/Scheduler/schedulerCalendar";
// import CreateBlock from "../containers/Blocks/createBlock";
// import CreateSchedule from "../containers/Template/createSchedule";
// import SchedulerList from "../containers/Scheduler/schedulerList";
import OrderList from "../containers/Orders/orderList";
// import ScheduleList from "../containers/Template/scheduleList";
// import CreateRoom from "../containers/Room/createRoom";
// import RoomVisualizer from "../containers/Room/visualizer";
import Logout from "../components/logout";
import KYC from '../containers/kyc/KYC';
import KycFailed from '../containers/kyc/KycFailed';


const ReactRouter = () => {
  return (
    <AuthProvider>
      <Header />
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />  
        <Route exact path='/kyc' component={KYC}/> 
        <Route exact path='/kycfailed' component={KycFailed}/> 
        <ProtectedRoute exact path="/dashboard" component={OrderList} />
      </Switch>
    </AuthProvider>
  );
};

export default ReactRouter;
