import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import logger from 'redux-logger'
import * as historyObj from "history";
import createRootReducer from "../reducers/rootReducer";
import rootSaga from "../sagas";

const sagaMiddleware = createSagaMiddleware();
export const history = historyObj.createBrowserHistory();

const initialState = {};
const enhancers: any[] = [];
const middleware = [sagaMiddleware, routerMiddleware(history), logger];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

export default createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers
);

sagaMiddleware.run(rootSaga);
