import React from 'react';

class ImagePreview extends React.Component{
    render(){
        let {imgSrc} = this.props;
        return(
            <div className="image-preview-container">
                <div className="close-preview" onClick={()=>{this.props.toggleImagePreview(null)}}>X</div>
                <img src={imgSrc} alt=" Preview" />
            </div>
        )
    }
}

export default ImagePreview;