

import {
    put,
    takeEvery,
    takeLatest,
    take,
    call
  } from "redux-saga/effects";
  import {firebase} from "@firebase/app";
  import { rsf } from "../firestore";
  import * as types from "../constants/ActionTypes";
  import axios from 'axios';

function kycApiHandler(url , data,authToken){
    console.log("calling api handler")
    let resData;
    return axios.post(url, data,
        {
            headers: { 'Authorization': "bearer " + authToken }
        }
    )
        .then(({ data }) => {
            // console.log("data is :",data);
            // this.setState({ data,responseStatus:200 });
            // this.props.toggleOptionsDisplay(true)
           let  responseStatus = 200;
           return {data,responseStatus};
       
        })
        .catch(e=>{
            console.log("error :",e.response);
            return {responseStatus:e.response.status};
            // this.setState({responseStatus:e.response.status});
            // this.props.toggleOptionsDisplay(false)        }) 
        })
    
}
function statsApiHandler(data,authToken){
    console.log("stas api",data,authToken);
    let baseUrl = process.env.REACT_APP_FETCH_URL;
     let url = baseUrl + '/twirp/pb.Watson/ProvideWildUploads';
    return axios.post(url,data,
        {
            headers: { 'Authorization': "bearer " + authToken }
        }
        )
                .then(res=>{
                    console.log(res)
                    return res;
                })
                .catch(e=>{
                    console.log("error in stats api",e)
                })

}
export function* fetchKycDocData({payload}){    
    console.log("running saga for fetch",payload)
          let resData;
          let url, data;
          var baseUrl = process.env.REACT_APP_FETCH_URL
          let {orderId,playerId,documentType,authToken} = payload;
          if (documentType && documentType === "PAN") {
          console.log("----fetching pan details---")
              url = baseUrl +"/twirp/pb.Watson/GetPanDetails";
              data = {
                  "player_id": playerId
              }
          }
          else {
              url = baseUrl + "/twirp/pb.Watson/GetKycDocumentDetails";
              data = {
                  "order_id": orderId
              }
          }
          console.log("auth token: in saga",authToken);
           resData= yield call(kycApiHandler,url,data,authToken);
         let currDocType = (documentType && documentType === "PAN") ? "pan" : "other";
         let actionType = currDocType==="pan" ? types.GET_KYC_PAN_DATA : types.GET_KYC_DOC_DATA;
         let failureActionType = currDocType==="pan" ? types.GET_KYC_PAN_DATA_FAILURE : types.GET_KYC_DOC_DATA_FAILURE ;
              console.log("resData before sending to reducer",resData)
              if(resData.responseStatus == 200)
              {
                yield put({type:actionType,resData});
              }
              else{
                  let action ={};
                       action.responseStatus = resData.responseStatus;
                  yield put({type:failureActionType,...action})
              }
             
  }

  export function* incrementWildUploadCount({payload,orderId,authToken}){
      console.log("SAGA-WILD",payload);
   let uploadRes = yield call(statsApiHandler,payload,authToken);
   console.log("upload res",uploadRes);
   let temp={};
   temp.orderId = orderId;
   temp.playerId = payload.playerId;
   temp.authToken = authToken;
   yield put({type:types.GET_KYC_DATA,payload:temp})
   
  }

  //the below worker saga fetches order history
  export function* fetchKycOrderHistoryData({payload}){    
    console.log("running saga for fetch order history",payload)
          let resData;
          let url, data;
          var baseUrl = process.env.REACT_APP_FETCH_URL
          let {orderId,authToken} = payload;
        
        
              url = baseUrl + "/twirp/pb.Watson/GetKycDocumentDetails";
              data = {
                  "order_id": orderId
              }
          
          console.log("auth token: in saga",authToken);
           resData= yield call(kycApiHandler,url,data,authToken);
           console.log("resData before sending to reducer",resData)
           if(resData.responseStatus == 200)
           {
             yield put({type:types.GET_KYC_CENTRAL_ORDER_DATA,resData});
           }
           else{
               let action ={};
                    action.responseStatus = resData.responseStatus;
               yield put({type:types.GET_KYC_CENTRAL_ORDER_DATA_FAILURE,...action})
           }
          
             
  }

  export default function* kycDocDataWatcher() {
   
    yield takeEvery(types.GET_KYC_DATA, fetchKycDocData);
    yield takeEvery(types.GET_KYC_ORDER_DATA, fetchKycOrderHistoryData);
    yield takeEvery(types.INCREMENT_WILD_UPLOAD_COUNT,incrementWildUploadCount)
   
}
  