import React  from 'react';
import CentralOrders from './CentralOrders';

class KycStats extends React.Component{
    state={
        data:null,
        isPanLoading:false,
        isOtherLoading:false,
        showCentralOrders:false,
        showConfirmationScreen:false,
        confirmScreenData:null,
        wildUploadData:null
    }
    componentDidMount(){
        let {uploadCountInfo} = this.props;
        console.log("upload count info",uploadCountInfo)
        this.parseUploadData(uploadCountInfo);
    }
    componentWillReceiveProps(props){
        let {uploadCountInfo} = props;
        console.log("component will receive props",uploadCountInfo)
        this.parseUploadData(uploadCountInfo);
    }
    handleLoading = (value) => {
        this.setState({isPanLoading:value,isOtherLoading:value})
    }
    parseUploadData =(data) =>{
        let temp ={};
        temp.pan={};
        temp.other={};
        temp.order_history = [...data.order_history];
        let panData = data["pan"];
        let otherDocData = data["other_documents"];
        temp.pan.user_upload_count = panData.user_upload_count !== undefined ? panData.user_upload_count : "0";
        temp.pan.default_uploads_given = panData.default_uploads_given !== undefined ? panData.default_uploads_given : "0";
        temp.pan.wild_uploads_given = panData.wild_uploads_given!== undefined ? panData.wild_uploads_given : "0";

        temp.other.user_upload_count = otherDocData.user_upload_count !== undefined ? otherDocData.user_upload_count : "0";
        temp.other.default_uploads_given = otherDocData.default_uploads_given !== undefined ? otherDocData.default_uploads_given : "0";
        temp.other.wild_uploads_given = otherDocData.wild_uploads_given!== undefined ? otherDocData.wild_uploads_given : "0";
      
        console.log(temp);
        this.setState({data:temp,isPanLoading:false,isOtherLoading:false})

    }
    toggleCentralOrdersDisplay =()=>{
        this.setState(state => {
            return { showCentralOrders : !state.showCentralOrders}
        })
    }
    displayConfirmationScreen = (data,wildUploadData) => {
        this.setState({showConfirmationScreen:true,confirmScreenData:data,wildUploadData})
    }
    hideConfirmationScreen = () =>{
    this.setState(state=> {
        return {showConfirmationScreen : false,wildUploadData:null}
    }
    )
}
    onProceedClick = () =>{
        let {wildUploadData} = this.state;
        this.hideConfirmationScreen();
        this.handleLoading(true);
        this.props.handleWildUpload(wildUploadData);
    }
    render(){
        let {data,isPanLoading,isOtherLoading,showCentralOrders} = this.state;
       let {showConfirmationScreen,confirmScreenData} = this.state;
        return (
            <>
             
             {data!==null ? <>
                <div className="btn-central" onClick={this.toggleCentralOrdersDisplay}>View Order History</div>
                <div className="stats-container">
                <div className="header-row ">
                    <div className="stats border1 padBox width200 statsBox">STATS</div>
                    <div className="user-uploads border1 padBox">User Uploaded Count</div>
                    <div className="default-uploads border1 padBox">Default Upload Limit (A)</div>
                    <div className="wild-uploads border1 padBox">Wild Upload Limit (B)</div>
                    <div className="border1 padBox">Total Upload Limit (A+B)</div>
                </div>
                <div className="pan-row">
                    <div className="border1 padBox width200">PAN</div>
                    <div className="border1 padBox"><span>{data.pan.user_upload_count}</span></div>
                    <div className="pan-default border1 padBox "><span></span><span className="spanStyle1">{data.pan.default_uploads_given}</span></div>
                    <div className="pan-wild border1 padBox ">{isPanLoading ? <span>...</span>:<span className="spanStyle1">{data.pan.wild_uploads_given}</span>}<span className="spanList"><RenderList id="panList" displayConfirmationScreen={this.displayConfirmationScreen}  /></span></div>
                    <div className="border1 padBox ">{parseInt(data.pan.default_uploads_given)+parseInt(data.pan.wild_uploads_given)}</div>
                </div>
                <div className="otherDoc-row">
                    <div className="border1 padBox width200">Other Documents</div>
                    <div className="border1 padBox"><span>{data.other.user_upload_count}</span></div>
                    <div className="other-default border1 padBox"><span></span><span className="spanStyle1">{data.other.default_uploads_given}</span></div>
                    <div className="other-wild border1 padBox">{isOtherLoading ? <span>...</span>:<span className="spanStyle1">{data.other.wild_uploads_given}</span>}<span className="spanList"><RenderList id="otherList" displayConfirmationScreen={this.displayConfirmationScreen}    /></span></div>
                    <div className="border1 padBox ">{parseInt(data.other.default_uploads_given)+parseInt(data.other.wild_uploads_given)}</div>
                </div>
                {showConfirmationScreen ? <ConfirmationScreen data={confirmScreenData} onProceedClick = {this.onProceedClick} hideConfirmationScreen={this.hideConfirmationScreen}  /> :null}
            </div>
            {showCentralOrders ? <CentralOrders playerId={this.props.playerId} toggleCentralOrdersDisplay={this.toggleCentralOrdersDisplay} data={data.order_history}/> : null}
          
            </> :<div> Loading Stats...</div>}
            </>
        )
    }
}

const RenderList = ({id,displayConfirmationScreen}) =>{
    const handleChange = (e) =>{
        console.log("exec pan select",e.target.id);
        let data = {};
        let documentType;
        if(e.target.value !== "+")
        {
            
            if(e.target.id === "panList")
            {
                data.pan = parseInt(e.target.value);
                data.other_document =0;
                documentType="pan"
            }
            else{
                data.pan = 0;
                data.other_document = parseInt(e.target.value);
                documentType = "other document"
            }
            
        }
        let obj={};
         obj.documentType = documentType;
        obj.count = documentType === "pan" ? data.pan : data.other_document;
       
       //the below fn call will send data for confrimation screen, 
       //wild upload count data respectively
        displayConfirmationScreen(obj,data);
  
        e.target.selectedIndex = 0;
    }
    return (
        <>
        <select id={id} defaultValue="+" onChange={handleChange}>
            <option value="+" disabled>+</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
        </select>
        </>
    )
}
export default KycStats;


const ConfirmationScreen = (props) =>{
    console.log("confirmScreen",props.hideConfirmationScreen);
    let {data,onProceedClick} = props;
    return(
        <div className="custom-modal-container">
        <div className="custom-modal">
     <div className="modal-text">
            Increment {data.documentType.toUpperCase()}'s wild upload count by {data.count}
          </div>
         
          <div className="modal-buttons">
            <button className="btn-cancel" onClick={props.hideConfirmationScreen}>
              Cancel
            </button>
            <button className="btn-proceed" onClick={onProceedClick}>
              Proceed
            </button>
        </div>
        </div> 
        </div>
    )
}