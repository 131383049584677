
import {
    put,
    takeEvery,
    take,
  } from "redux-saga/effects";
  import {firebase} from "@firebase/app";
  import { rsf } from "../firestore";
  import * as types from "../constants/ActionTypes";
  import axios from 'axios';

export  function* syncKycOrders(){
    console.log("++++++++++kycorders++++++++++")
  const colRef = rsf.app.firestore().collection("central_orders")
  const query = colRef.where("Category", "==", "Kyc").where("State","==","Manual_Supervision").orderBy("CreatedAt", "desc")
  const channel = rsf.firestore.channel(query);
console.log("channel:",channel)
  while(true) { 
    console.log("Waiting for data in kyc orders");
    const snapshot = yield take(channel);
    console.log("data recieved", snapshot);
    let list = {};
    snapshot.forEach(function(e) {
        console.log("iterating kyc")
      list = {
        ...list,
        [e.id]: {
          ...e.data(),
          id: e.id
        }
      };
    });
    console.log("yielding get kyc orders")
    yield put({ type: types.GET_KYC_ORDERS, list: Object.values(list), map: list });
}
}


export default function* kycOrders() {
 
      yield takeEvery(types.SYNC_KYC_ORDERS, syncKycOrders);
     
  }