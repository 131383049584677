import React from 'react';
import { connect } from 'react-redux';
import { syncKycFailedOrders } from '../../actions/kycActions';
import OrderRows from './kycRow';
class KycFailed extends React.Component {
    componentDidMount() {
    
       console.log("-------------kyc failed component did mount----------")
       this.props.syncKycFailedOrders();
    }
    filterListData = (playerId) => {
     this.props.syncKycFailedOrders(playerId);   
    }
    render() {
        console.log("RENDER kyc failed comp");
        console.log('props:',this.props)
        return (
            <div className="kyc-list">
                <OrderRows
                    data={this.props.kycOrderData}
                    isLoading = {this.props.isLoading}
                    filterListData = {this.filterListData}
                />
               
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    console.log("state in kyc failed comp",state)
   return {
       orderData: state.kycFailedData.orders,
       kycOrderData:state.kycFailedData.kycOrders,
       isLoading:!state.kycFailedData.isReady
   }
}
const mapDispatchToProps = {
    syncKycFailedOrders,
    
}
export default connect(mapStateToProps, mapDispatchToProps)(KycFailed);
