import * as React from "react";
import { Table } from "tabler-react";
import moment from "moment";
import editImg from "../../assets/Edit.png";
import deleteImg from "../../assets/Delete.png";
import KycDoc from "./KycDoc";
import {connect} from 'react-redux';
import {fetchKycDocument} from "../../actions/kycActions";


interface Props {
  data: any;
}

class OrderRows extends React.Component<Props, {}> {
  state = {
    showKycDoc: false,
    payments: [],
    fullfillments: [],
    refunds: [],
    orderId: "",
    playerId:"",
    headerFields: [
      "SNo",
      "Title",
      "Order ID",
      "Category",
      "Product",
      "State",
      "PlayerId", 
      "Date Created",
    ],
    scrollPosition:0
  };
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    console.log("###component mounted->>>>>>>>>>>>>>>")
    // let self = this;
    //     window.addEventListener('scroll', function () {
    //         console.log("kyc scroll", window.pageYOffset);
            
    //         });
  }
 componentDidUpdate(){
  //  console.log("component is updated++++++++++");
  //  console.log()
  //  window.scrollTo(0,this.state.scrollPosition)
 }
  showKycDoc = (e,orderId,playerId) => this.setState((state) => {
    
    console.log("<<<<<<<<window y offset->>>",window.pageYOffset);
    this.props.fetchKycDocument();
    return { showKycDoc: !state.showKycDoc,orderId,playerId,scrollPosition:window.pageYOffset}
})

  hideKycDoc= (scrollPosition) =>
  {
    console.log("------hiding kyc doc",scrollPosition);
    this.setState({ showKycDoc: false,scrollPosition:scrollPosition});
  } 

  render() {
    const { data, isLoading,filterListData } = this.props;
    let {orderId,playerId,scrollPosition} = this.state;
    console.log(")))initially data for kyc row",data)
    var mainTable = (
      <>
      {
        this.state.showKycDoc ?
        <KycDoc hideKycDoc={this.hideKycDoc} orderId={orderId} playerId={playerId} scrollPosition={scrollPosition}/>
        :
        <div>
       {!isLoading ?  
        
          data && data.length > 0 ?
         <List data={data} headerFields={this.state.headerFields} showKycDoc={this.showKycDoc} scrollPosition={scrollPosition} filterListData={filterListData}/>
          :
          <div style={{'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
          <h4 style={{'padding': '30px'}}>No Kyc Orders</h4>
          </div>
         : <div style={{'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
         <h4 style={{'padding': '30px'}}>Loading ..</h4>
         </div>
        }
        </div>
      }

    </>
  )

    return (mainTable);
  }
}
const mapDispatchToProps = {
  fetchKycDocument
}
export default connect(null,mapDispatchToProps)(OrderRows);


class List extends React.Component{
  state={
    filterText:''
  }
  componentDidMount(){
    console.log("list comp is mounted#############################");
    let {scrollPosition} = this.props;
    console.log("scorll pos "+scrollPosition)
      window.scrollTo(0,scrollPosition)
    
   
  }
  componentDidUpdate(){
    console.log("list comp is updated!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
  }
  componentWillMount(){
  
  }
  componentWillUnmount(){
  
    console.log("list is unmounting))))))))))))))))))))))))))))))))")
  }
  onChangeHandler = (e) => {
    let {value} = e.target;
    this.setState({filterText : e.target.value})
    if(value.trim() === "")
    {
      this.props.filterListData(value);
    }
  }
 
  render(){
    let {data,headerFields,showKycDoc,filterListData} = this.props;
    let {filterText} = this.state;
    return(
      <>
    {window.location.pathname === '/kycfailed' ?  <div className="filter-block">
        <input type="text" value={filterText} placeholder="Filter BY PlayerId" onChange={this.onChangeHandler}></input>
        <button onClick={()=>filterListData(filterText)}>Go</button>
      </div> : null}
 <Table>
          <Table.Header>
            {headerFields.map((o, i) => (
              <Table.ColHeader key={i}>{o}</Table.ColHeader>
            ))}
          </Table.Header>
            <Table.Body>
              {data &&
                data.map((ob, i) => (
  
                  <Table.Row key={i} id={i} style={{ color: 'rgba(255,255,255,0.8)' }} onClick={(e) => showKycDoc(e,ob.id,ob.PlayerId)}>
                   <Table.Col>{i+1}</Table.Col>
                    <Table.Col>{`${ob.Product.KycDocumentType}`}</Table.Col>
                    <Table.Col>{`${ob.id}`}</Table.Col>
                    <Table.Col>{`${ob.Category}`}</Table.Col>
                    <Table.Col>{ob.Product.Type}</Table.Col>
                    <Table.Col>{ob.State}</Table.Col>

                    <Table.Col>
                      {ob.PlayerId}
                    </Table.Col>
                    <Table.Col>
                      {moment.unix(ob.CreatedAt.seconds).format("MMMM Do YYYY, h:mm:ss a")}
                    </Table.Col>
                    
                  </Table.Row>
                ))
              }
            </Table.Body>
            </Table>
      </>
    )
  }
}