import * as types from "../constants/ActionTypes";

const initialState = {
    data: null,
    responseStatus:0,
    optionsDisplay:false
};

export default (state = initialState, action) => {
    console.log("111111111111111111111inside kycdocdata reducer111111111111111111111")
    console.log("action is :",action)
  switch (action.type) {
    
    case types.GET_KYC_DOC_DATA:
        console.log("inside case stmnt")
      return {
        ...state,
        data: {...action.resData.data},
        responseStatus:action.resData.responseStatus,
        optionsDisplay:true
        
      };
    case types.GET_KYC_DOC_DATA_FAILURE:
        console.log("failure");
        return {
            data:null,
            responseStatus:action.responseStatus,
            optionsDisplay:false
        }

    case types.FETCHING_KYC_DOC :
        console.log("red fetching kyc doc")
        return {
            data: null,
            responseStatus:0,
            optionsDisplay:false
        }
    
    default:
      return state;
  }
};
