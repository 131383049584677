
import {
    put,
    takeEvery,
    take,
  } from "redux-saga/effects";
  import {firebase} from "@firebase/app";
  import { rsf } from "../firestore";
  import * as types from "../constants/ActionTypes";
  import axios from 'axios';

export  function* syncKycFailedOrders({payload}){
    console.log("++++++++++kycfailedorders++++++++++")
  const colRef = rsf.app.firestore().collection("central_orders")
  const query = colRef.where("Category", "==", "Kyc").where("State","==","Failed").orderBy("CreatedAt", "desc")
  const channel = rsf.firestore.channel(query);

  while(true) { 
    console.log("Waiting for data in kyc failed orders");
    const snapshot = yield take(channel);
    console.log("failed orders data recieved", snapshot);
    let list = {};
    snapshot.forEach(function(e) {
        console.log("iterating kyc")
      list = {
        ...list,
        [e.id]: {
          ...e.data(),
          id: e.id
        }
      };
    });
    let data;
    //code for filter if exists

    if(payload.filterId && payload.filterId !== undefined)
    {
     data = Object.values(list).filter(item=>{
      return item.PlayerId === payload.filterId.trim();
     })
     if(data.length === 0)
     {
      data = [...Object.values(list)];
     }
     console.log("filtered data :",data)
    }
    else{
      data = [...Object.values(list)];
    }
   
    console.log("yielding get kyc failed orders")
    yield put({ type: types.GET_KYC_FAILED_ORDERS, list: data, map: list });
}
}


export default function* kycFailedOrders() {
 
      yield takeEvery(types.SYNC_KYC_FAILED_ORDERS, syncKycFailedOrders);
     
  }