import * as types from "../constants/ActionTypes";

const initialState = {
    data: null,
    responseStatus:0,
    isFetching:false
};

export default (state = initialState, action) => {
    console.log("111111111111111111111inside kycdocdata reducer111111111111111111111")
    console.log("action is :",action)
  switch (action.type) {
    
    case types.GET_KYC_CENTRAL_ORDER_DATA:
        console.log("inside case stmnt")
      return {
        ...state,
        data: {...action.resData.data},
        responseStatus:action.resData.responseStatus,
        isFetching:false
      };
    case types.GET_KYC_CENTRAL_ORDER_DATA_FAILURE:
        console.log("failure");
        return {
            data:null,
           responseStatus:action.responseStatus, 
           isFetching:false      
        }
    case types.FETCHING_ORDER_HISTORY :
      return{
        isFetching:true
      }
    default:
      return state;
  }
};
