import React from 'react';
import OrderDocument from './OrderDocument';
class CentralOrders extends React.Component{
    state = {
        orderId :null
    }
    renderOrderList = ()=>{
     let {data} = this.props;
     let orders = data.map(order => {
         return order.replace('central_orders/','')
     })
     console.log("orders",orders);
     return orders.map(order => {
         return (
            
            <option key={order} value={order}>{order}</option>
         )
     });
     
    }
    handleOrderSelection = (e) => {
        let {value} = e.target;
        this.setState({orderId:value})

    }
    toggleOptionsDisplay = () =>{
        return true;
    }
    render(){
        console.log("central orders:",this.props);
        let {orderId} = this.state;
        console.log("order id in central orders:",orderId)
        return(
            <div className="central-container">
                <div className="central-header">
                    <div className="heading">
                        Central Orders
                    </div>
                    <div className="central-close" onClick={this.props.toggleCentralOrdersDisplay}>
                        X
                    </div>
                  
                </div>
                <hr className="header-separator"/>
                <div className="main-content">
                    <div className="central-list">
                        <select onChange={this.handleOrderSelection} defaultValue="header">
                        <option value="header" disabled >Select Order</option>
                        {this.renderOrderList()}
                        </select>
                        
                    </div>
                    <div className="central-order-view">
                   {orderId !== null? <OrderDocument  orderId={orderId}  playerId={this.props.playerId} toggleOptionsDisplay={this.toggleOptionsDisplay} fromCentralOrders={true} data={null}/> : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default CentralOrders;