export const GET_USERS = "GET_USERS";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SYNC_USER = "SYNC_USER";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const CHECK_USER = "CHECK_USER";
export const GET_ORDERS = "GET_ORDERS";
export const GET_KYC_ORDERS='GET_KYC_ORDERS';
export const REQUEST_ORDERS = "REQUEST_ORDERS";
export const SYNC_ORDERS = "SYNC_ORDERS";
export const SYNC_KYC_ORDERS = "SYNC_KYC_ORDERS";
export const GET_USER = "GET_USER";
export const REQUEST_USER = "REQUEST_USER";
export const GET_WALLETS = "GET_WALLETS";
export const REQUEST_WALLETS = "REQUEST_WALLETS";
export const GET_PAYOUT_PROFILE = "GET_PAYOUT_PROFILE";
export const REQUEST_PAYOUT_PROFILE = "REQUEST_PAYOUT_PROFILE";
// export const REQUEST_CREATE_BLOCK_OPTIONS = "REQUEST_CREATE_BLOCK_OPTIONS";
// export const GET_CREATE_BLOCK_OPTIONS = "GET_CREATE_BLOCK_OPTIONS";
// export const REQUEST_BLOCKS = "REQUEST_BLOCKS";
// export const GET_BLOCKS = "GET_BLOCKS";
// export const REQUEST_GAMES = "REQUEST_GAMES";
// export const GET_GAMES = "GET_GAMES";
// export const REQUEST_SCHEDULE = "REQUEST_SCHEDULE";
// export const GET_SCHEDULE = "GET_SCHEDULE";
export const REQUEST_ROOMS = "REQUEST_ROOMS";
// export const REQUEST_TEMPLATES = "REQUEST_TEMPLATES";
export const GET_ROOMS = "GET_ROOMS";
// export const GET_TEMPLATES = "GET_TEMPLATES";
// export const DELETE_BLOCKS = "DELETE_BLOCKS";
// export const DELETE_ROOM = "DELETE_ROOM";

export const GET_KYC_DATA = "GET_KYC_DATA";
export const GET_KYC_DOC_DATA = "GET_KYC_DOC_DATA";
export const GET_KYC_DOC_DATA_FAILURE = "GET_KYC_DOC_DATA_FAILURE";

export const GET_KYC_PAN_DATA = "GET_KYC_PAM_DATA";
export const GET_KYC_PAN_DATA_FAILURE = "GET_KYC_PAN_DATA_FAILURE";

export const INCREMENT_WILD_UPLOAD = "INCREMENT_WILD_UPLOAD";
export const INCREMENT_WILD_UPLOAD_COUNT = "INCREMENT_WILD_UPLOAD_COUNT";

export const GET_KYC_STATS_DATA = "GET_KYC_STATS_DATA";
export const GET_KYC_STATS_DATA_FAILURE = "GET_KYC_STATS_DATA_FAILURE";

export const GET_KYC_ORDER_DATA = "GET_KYC_ORDER_DATA";
export const GET_KYC_CENTRAL_ORDER_DATA = "GET_KYC_CENTRAL_ORDER_DATA";
export const GET_KYC_CENTRAL_ORDER_DATA_FAILURE = "GET_KYC_CENTRAL_ORDER_DATA_FAILURE";
export const FETCHING_ORDER_HISTORY = "FETCHING_ORDER_HISTORY";

export const GET_KYC_ORDER_DOC_DATA = "GET_KYC_ORDER_DOC_DATA";
export const GET_KYC_ORDER_DOC_DATA_FAILURE = "GET_KYC_ORDER_DOC_DATA_FAILURE";

export const SYNC_KYC_FAILED_ORDERS = "SYNC_KYC_FAILED_ORDERS";
export const GET_KYC_FAILED_ORDERS = "GET_KYC_FAILED_ORDERS";

export const FETCHING_KYC_DOC = "FETCHING_KYC_DOC";
export const FETCHING_PAN_DOC = "FETCHING_PAN_DOC";
