import * as types from "../constants/ActionTypes";

export const syncKycOrders = () => {
    console.log("kyc action creator")
    return {type: types.SYNC_KYC_ORDERS}
  };
  export const syncKycFailedOrders = (id) => {
    console.log("kyc FAILED action creator")
    //  id="m2r96SOSXahWQoW4rV76uU5k7gi1";
    let payload ={
      filterId:id
    }
    return {type: types.SYNC_KYC_FAILED_ORDERS,payload}
  };
export const getKycDocData = (orderId,playerId,documentType,authToken) =>{
  console.log("Running kyc action creator")
  let payload = {
    orderId,playerId,documentType,authToken
  }
  return {type:types.GET_KYC_DATA,payload }
}

export const getKycCentralOrderData = (orderId,authToken) =>{
  console.log("Running kyc action creator")
  let payload = {
    orderId,authToken
  }
  return {type:types.GET_KYC_ORDER_DATA,payload }
}

export const fetchOrderDetails = () =>{
  return {
    type:types.FETCHING_ORDER_HISTORY
  }
}

export const  incrementWildUpload = (data,orderId,authToken) =>{
 console.log("Action creator -wild ",data)
 let payload ={...data}
  return {
    type:types.INCREMENT_WILD_UPLOAD_COUNT,
    payload,
    orderId,
    authToken
  }
}

export const fetchKycDocument = () => {
  return {
    type:types.FETCHING_KYC_DOC
  }
}

export const fetchKycPanDocument = () => {
  console.log("fetching kyc pan a c")
  return {
    type:types.FETCHING_PAN_DOC
  }
}