import React from 'react';

import axios from 'axios';
import { connect } from "react-redux";
import { getKycDocData,incrementWildUpload } from '../../actions/kycActions';
import { pl } from 'date-fns/esm/locale';
import KycStats from './KycStats';
import ImagePreview from './ImagePreview';



class DisplayDocument extends React.Component {
    state = {
        data: null,
        responseStatus: 0,
        showImagePreview:false,
        imgPreviewSrc:null
    }
    componentDidUpdate() {
        console.log("component did update", this.props);
        let { toggleOptionsDisplay, optionsDisplay } = this.props;
        //toggling the top right options on page based on the success/failure scenario 
        //using optionsDisplay prop from the reducer
        toggleOptionsDisplay(optionsDisplay);
    }
    componentDidMount() {
        console.log("&&&&&--componentDidMount---&&&&");
        console.log("received order id:", this.props.orderId);
        console.log("received player id:", this.props.playerId)

        let { orderId, playerId, documentType, authToken } = this.props;
        this.props.getKycDocData(orderId, playerId, documentType, authToken);
        if(documentType && documentType === "PAN")
        {
            var item = document.getElementById('pan-doc');
            
            window.location.href="#pan-doc";
        }

    }

    
    getUserElements = () => {
        let { data } = this.props;
        console.log("user elements")
        let elements = [];
        if(Object.keys(data['details_by_user']).length === 0)
        {
            elements.push(<div key="no-user-data2" >No user data</div>)
            return elements;
        }
        for (let key in data['details_by_user']) {
            console.log(key)
            elements.push(<div key={key} className="label-wrapper"><div className="key">{this.capitalizeString(key)} <span>:</span></div><div>{data['details_by_user'][key]}</div></div>)
        }
        // elements.push(<hr />)
        return elements;
    }
    getChecksElements = (check) => {
        let { data } = this.props;
        let elements = [];

        let ele = []

        for (let key in check) {
            console.log(key)
            ele.push(<div key={key} className="label-wrapper"><div className="key">{this.capitalizeString(key)} <span>:</span></div><div>{check[key]}</div></div>)
        }

        ele.push(<br key="line-break" />)
        return ele;


        return elements;
    }
    getOcrElements = () => {
        let { data } = this.props;
        let elements = [];
        if(Object.keys(data['details_by_ocr']).length === 0)
        {
            elements.push(<div key="no-ocr-data2">No ocr data</div>)
            return elements;
        }
        for (let key in data['details_by_ocr']) {
            console.log(key)
            elements.push(<div key={key} className="label-wrapper"><div className="key">{this.capitalizeString(key)} <span>:</span></div><div>{data['details_by_ocr'][key]}</div></div>)
        }
        return elements;
    }
    toggleImagePreview = (src) =>{
        this.setState(state =>{
            return { showImagePreview : !state.showImagePreview,imgPreviewSrc:src}
        })
    }
    renderImages = () => {
        let { data } = this.props;
        let images = [];
        if (data && data !== null) {
            if (data.file_urls.front) {
                images.push(<div key="front" className='image-container'>
                    <img src={data.file_urls.front} onClick={()=>{this.toggleImagePreview(data.file_urls.front)}}/>
                </div>)
            }
            if (data.file_urls.back) {
                images.push(<div key="back" className='image-container'>
                    <img src={data.file_urls.back} onClick={()=>{this.toggleImagePreview(data.file_urls.back)}}/>
                </div>)
            }
        }

        return images;
    }
    capitalizeString = (str) => {
        var splitStr = str.toLowerCase().split('_');
        for (var i = 0; i < splitStr.length; i++) {

            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    handleWildUpload = (data) =>{
        console.log("data for wild upload",data)
        let { orderId, playerId, documentType, authToken } = this.props;
        data.player_id = playerId;

        this.props.incrementWildUpload(data,orderId,authToken);
        
    }
    render() {
         let { imgPreviewSrc,showImagePreview } = this.state;
        let { data, responseStatus } = this.props;
        let {orderId,playerId} = this.props;
        let errMsg = (responseStatus >= 400 && responseStatus < 500) ? 'User is not authorized to view the doc' : responseStatus >= 500 ? 'Document not found' : '';
        let id = 'other-doc';
        console.log("document data: in render ", data)

        return (
            <>
                {data && data !== null ? (<><div className='kyc-doc'>
                  {!(this.props.documentType === "PAN") ?<KycStats playerId ={playerId} uploadCountInfo={data.upload_count_info} handleWildUpload={this.handleWildUpload}/> :null }
                    <div className="kyc-doc-type" id={id}>
                        DOCUMENT TYPE : {data.document_type || ''}
                    </div>
                    <div className="current-order">
                        <div>Order <span>&nbsp;</span>Id : {orderId}</div>
                        <div>Player Id : {playerId}</div>
                    </div>
                    <div className="kyc-content">
                        <div className="kyc-images">
                            {this.renderImages()}
                        </div>

                        <div className='details-container pad'>
                            <div className="details-user">
                            {data['details_by_user'] &&( <div className="user">
                                    <label className="label-heading">DETAILS BY USER  </label>
                                    <div className="user-data">
                                        {this.getUserElements()}
                                    </div>

                                </div>) }
                                {data['details_by_ocr'] && (<div className="details-ocr">
                                    <label className="label-heading">DETAILS BY OCR  </label>
                                    <div className="ocr-data">
                                        {this.getOcrElements()}
                                    </div>
                                </div>) }

                            </div>
                          {data['checks_details'] && data['checks_details'].length>0 ?(  <div className="checks">
                                <label className="label-heading">CHECKS DETAILS </label>
                                <div className="checks-data">
                                    {data['checks_details'].map((checkObj, i) => {
                                        return (
                                            <div key={i} className="check-entry">
                                                {this.getChecksElements(checkObj)}
                                            </div>
                                        )
                                    })}

                                </div>

                          </div>) : null}
                        </div>
                    </div>
                </div>
                    <hr /></>) : (!(responseStatus >= 400)) ? <div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                        <h4 style={{ 'padding': '30px' }}>Loading Doc..</h4>
                    </div> : <div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                            <h4 style={{ 'padding': '30px' }}>{errMsg}</h4>
                        </div>}
                        {showImagePreview ? <ImagePreview imgSrc={imgPreviewSrc} toggleImagePreview={this.toggleImagePreview}/> :null}
            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    console.log("state inside mapstatetoprops:", state)
    return {
        authToken: state.loginState.idToken,
        data: state.kycDocData.data,
        responseStatus: state.kycDocData.responseStatus,
        optionsDisplay: state.kycDocData.optionsDisplay
    };
};

const mapDispatchToProps = {
    getKycDocData,
    incrementWildUpload
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisplayDocument);
