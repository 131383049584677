import * as React from "react";
import Drawer from "rc-drawer";
import "rc-drawer/dist/rc-drawer.css";

const DrawerLeft = props => {
  return (
    <Drawer {...props}>
      <div tabIndex={0}>{props.children}</div>
    </Drawer>
  );
};

export default DrawerLeft;
