import React from 'react';
import { connect } from 'react-redux';
import { syncKycOrders } from '../../actions/kycActions';
import { syncOrders } from '../../actions/formActions';
// import OrderRows from "../Orders/orderRow";
import OrderRows from './kycRow';
class KYC extends React.Component {
    componentDidMount() {
    //    this.props.syncOrders('dev-1');
       console.log("-------------kyc component did mount----------")
       this.props.syncKycOrders();
    }
    
    render() {
        console.log("RENDER kyc comp");
        console.log('props:',this.props)
        return (
            <div className="kyc-list">
                <OrderRows
                    data={this.props.kycOrderData}
                    isLoading = {this.props.isLoading}
                />
                {/* <p>KYC</p> */}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    console.log("state in kyc comp",state)
   return {
       orderData: state.orderState.orders,
       kycOrderData:state.kycOrderState.kycOrders,
       isLoading:!state.kycOrderState.isReady
   }
}
const mapDispatchToProps = {
    syncKycOrders,
    syncOrders
}
export default connect(mapStateToProps, mapDispatchToProps)(KYC);
