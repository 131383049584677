import * as React from "react";
import { Grid, Header as H, Nav, AccountDropdown, Icon } from "tabler-react";
import { SubMenu, Item as MenuItem, Divider } from "rc-menu";
import { withRouter } from "react-router-dom";
import { AuthConsumer } from "../Contexts/AuthContext";
import { Link } from "react-router-dom";
import DrawerLeft from "./DrawerLeft";
import handle from "../assets/browse.png";
import CommonMenu from "./Menu";

const nav = [
  { text: "Profiles", viewLink: "/comingSoon"},
  {
    text: "Orders",
    viewLink: "/dashboard"
  },
  {
    text: "KYC",
    viewLink: "/kyc",
    submenu:[
      {
        text:"KYC List",
        viewLink:"/kyc"
      },
      {
        text:"KYC Failed",
        viewLink:"/kycfailed"
      }
]
  },
  {
    text: "Rooms",
    viewLink: "/comingSoon"
  }
 
];
interface Props {
  location: any;
}
interface State {
  left: boolean;
}

class Header extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      left: false
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  close = () => {
    this.setState({ left: false });
  };

  toggleDrawer = open => () => {
    this.setState(
      {
        left: open
      }
    );
  };
  render() {
    return (
      
      <AuthConsumer>
        {({ isAuth, logout, user }: any) => (
          <>
            <div className="nav">
              <Grid.Row>
                <Grid.Col md={12}>
                  <Nav>
                    <Grid.Col md={5}>
                      <img
                        className="handle-nav"
                        src={handle}
                        width="4%"
                        onClick={this.toggleDrawer(true)}
                      />
                    </Grid.Col>
                    <Grid.Col md={5} className="title">
                      <Link to="/dashboard">
                        <H.H2>CS@MEGA</H.H2>
                      </Link>
                    </Grid.Col>
                    <Grid.Col md={1}>
                      <AccountDropdown
                        avatarURL="./demo/faces/female/25.jpg"
                        options={[
                          [user],
                          {
                            to: "#",
                            value: [user]
                          },
                          "logout",
                          {
                            to: "/logout"
                          }
                        ]}
                      />
                    </Grid.Col>
                  </Nav>
                </Grid.Col>
              </Grid.Row>
            </div>
            <DrawerLeft
              width="370px"
              getContainer={null}
              placement="left"
              open={this.state.left}
              handler={null}
              onMaskClick={this.toggleDrawer(false)}
            >
              <Grid.Col className="routes">
                <CommonMenu mode="inline">
                  {nav.map((e, i) => {
                  if(e.submenu)
                  {
                  return  (
                  
                  <SubMenu title="KYC" key={"submenu"+i}>
                       {e.submenu.map((item,i) =>{
                         return( <MenuItem key={i}>
                          <Link
                            to={{
                              pathname: `${item.viewLink}`,
                              state: { from: this.props.location.pathname }
                            }}
                            onClick={this.close}
                          >
                            {item.text}
                            <Icon name="chevron-right" />
                          </Link>
                        </MenuItem>)
                       })}
                    
                    </SubMenu>
                   )
                  }  
                  return (<MenuItem key={i}>
                      <Link
                        to={{
                          pathname: `${e.viewLink}`,
                          state: { from: this.props.location.pathname }
                        }}
                        onClick={this.close}
                      >
                        {e.text}
                        <Icon name="chevron-right" />
                      </Link>
                    </MenuItem>)
                  })}
                  
                </CommonMenu>
              </Grid.Col>
            </DrawerLeft>
          </>
        )}
      </AuthConsumer>
    );
  }
}

export default withRouter(Header);
