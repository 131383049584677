import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
//import graphReducer from "./graphReducer";
import loginReducer from "./loginReducer";
import formReducer from "./formReducer";
import kycReducer from "./kycReducer";
 import kycDocDataReducer from './kycDocDataReducer.ts';
import kycStatsReducer from "./kycStatsReducer";
import kycPanReducer from "./kycPanReducer";
import kycCentralOrderReducer from "./kycCentralOrderReducer";
import kycFailedReducer from "./kycFailedReducer";

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    loginState: loginReducer,
    orderState: formReducer,
    kycOrderState: kycReducer,
    kycFailedData : kycFailedReducer,
    kycDocData:kycDocDataReducer,
    kycStats:kycStatsReducer,
    kycPanData:kycPanReducer,
    kycOrderHistory:kycCentralOrderReducer
    });
