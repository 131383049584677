import * as types from "../constants/ActionTypes";

const initialState = {
    kycOrders: [],
  isReady: false
};

export default (state = initialState, action) => {
  console.log("inside kyC FAILED reducer");
  switch (action.type) {
    
    case types.GET_KYC_FAILED_ORDERS:
      console.log("inside case of kyc FAILED reducer")
      return {
        ...state,
        kycOrders: action.list,
        map: action.map,
        isReady: true
      };
    
    default:
      return state;
  }
};
