/* eslint-disable no-unused-expressions */
import {
  cancel,
  cancelled,
  put,
  takeEvery,
  takeLatest,
  delay,
  all,
  call,
  take,
  fork
} from "redux-saga/effects";
import {firebase} from "@firebase/app";
import { rsf } from "../firestore";
import * as types from "../constants/ActionTypes";


export function* getPublicProfile(action){
  //const docRef = rsf.app.firestore().document("players/"+action.playerId);
  //const snapshot = rsf.firestore.getDocument(docRef);

  const snapshot = yield call(rsf.firestore.getDocument, "players/"+action.playerId);

  const user = snapshot.data();

  yield put({ type: types.GET_USER, user: user});
}

export function* getWallets(action){

  const colRef = rsf.app.firestore().collection("players/"+action.playerId+"/wallets")
  const channel = rsf.firestore.channel(colRef)

  while(true){
    console.log("Waiting for data");
    const snapshot = yield take(channel);
    console.log("data recieved", snapshot);
    let list = {};
    snapshot.forEach(function(e) {
      list = {
        ...list,
        [e.id]: {
          ...e.data(),
          id: e.id
        }
      };
    });

    yield put({ type: types.GET_WALLETS, list: Object.values(list)});
  }
}

export function* getPayoutProfile(action){
  // const colRef = rsf.app.firestore().collection("players/"+action.playerId+"/private/payoutProfile")
  // const channel = rsf.firestore.channel(colRef)

  const snapshot = yield call(rsf.firestore.getDocument, "players/"+action.playerId+"/private/payoutProfile");

  const payoutProfile = snapshot.data();


    yield put({ type: types.GET_PAYOUT_PROFILE, payoutProfile: payoutProfile});
}



export function* syncOrders(action){
  console.log("Action is ", action);
  const colRef = rsf.app.firestore().collection("central_orders")
  const query = colRef.where("PlayerId", "==", action.playerId).limit(600).orderBy("CreatedAt", "desc")
  const channel = rsf.firestore.channel(query);

  while(true) {
   console.log("Waiting for data");
   const snapshot = yield take(channel);
   console.log("data recieved", snapshot);

   let list = {};
   snapshot.forEach(function(e) {
     console.log("iterating",e.id);
     list = {
       ...list,
       [e.id]: {
         ...e.data(),
         id: e.id
       }
     };
   });
   console.log("list:",list);
   yield put({ type: types.GET_ORDERS, list: Object.values(list), map: list });
 }
}

export function* getOrders() {
  const snapshot = yield call(
    rsf.firestore.getCollection,
    "central_orders"
  );
  console.log(snapshot)
  let list;
  snapshot.forEach(function(e) {
    list = {
      ...list,
      [e.id]: {
        ...e.data(),
        id: e.id
      }
    };
  });
  console.log(list);
  yield put({ type: types.GET_ORDERS, list: Object.values(list) });
}

export default function* orders() {
  console.log("executing orders saga");
  //yield takeEvery(types.REQUEST_CREATE_BLOCK_OPTIONS, fetchCreateOptions),
    yield takeEvery(types.REQUEST_ORDERS, getOrders);
    yield takeEvery(types.SYNC_ORDERS, syncOrders);
    yield takeEvery(types.REQUEST_USER, getPublicProfile);
    yield takeEvery(types.REQUEST_WALLETS, getWallets);
    yield takeEvery(types.REQUEST_PAYOUT_PROFILE, getPayoutProfile);
}
