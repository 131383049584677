import React from 'react';
import GoBack from '../../assets/goback.png'
import CustomModal from '../../components/CustomModal';
import DisplayDocument from './DisplayDocument';
import PanDocument from './PanDocument';
import {fetchKycPanDocument} from "../../actions/kycActions";
import {connect} from 'react-redux';

class KycDoc extends React.Component{

    state={
       showPanDetails: false,
       showCustomModal: false,
       rejectReasonRequired: false,
       showOptions:false
    }

    toggleCustomModal = (value) =>{
        console.log("value is ",value )
        let rejectReasonRequired = value || false;
        console.log("in toggle",rejectReasonRequired)
        this.setState(state =>{
            return { showCustomModal:!state.showCustomModal,rejectReasonRequired:rejectReasonRequired}
        })
    }

    renderOptions =() => {
        let elements=[];
        let text = !this.state.showPanDetails?'Show PAN Details': 'Hide PAN Details';
        //order contains pan
        if(true){
            elements.push(<button key="showPan" id="showPan" onClick={this.togglePanDisplay}>{text} </button>)
        }
       if(window.location.pathname === "/kyc" )
       {
        elements.push(<button key="accept" id="accept" onClick={()=>this.toggleCustomModal(false)}>Accept</button>)
        elements.push(<button key="reject" id="reject" onClick={()=>this.toggleCustomModal(true)}>Reject</button>)
      } else if (window.location.pathname === "/kycfailed"){
        elements.push(<button key="accept" id="accept" onClick={()=>this.toggleCustomModal(false)}>Accept</button>)
      }

      return elements;
    }
    togglePanDisplay =() =>{
        this.setState(state => {
            if(!state.showPanDetails)
            {
                this.props.fetchKycPanDocument();
            }
            return {showPanDetails : !state.showPanDetails}
        })

    }
   toggleOptionsDisplay = (value) =>{
       console.log("setting the options visible",value)
       this.setState({showOptions:value})
   }
    render(){
        let {showCustomModal,rejectReasonRequired,showOptions} = this.state;
        console.log("in render reject reason is",rejectReasonRequired)
        let {orderId , playerId,scrollPosition} = this.props;
        console.log("scroll pos in kyc doc is",scrollPosition)
        return(
            <>
            <div className='kycdoc-container'>
                <div className="kyc-decision">
                    <div className ="goback" onClick={()=>{this.props.hideKycDoc(scrollPosition)}}>
                    <img src={GoBack} alt="Go Back"/>
                    </div>
                  {showOptions ? <div className="options">
                        {this.renderOptions()}
                        {/* <button id="accept">Accept</button>
                        <button id="reject">Reject</button> */}
                    </div> : null}

                </div>
                <DisplayDocument  orderId={orderId} playerId={playerId} toggleOptionsDisplay={this.toggleOptionsDisplay}/>
                {this.state.showPanDetails ? <PanDocument documentType="PAN" orderId={orderId} playerId={playerId} toggleOptionsDisplay={this.toggleOptionsDisplay}/> : null }



            </div>
            {showCustomModal ? <CustomModal orderId = {this.props.orderId} rejectReasonRequired={rejectReasonRequired} toggleCustomModal={this.toggleCustomModal} hideKycDoc={()=>{this.props.hideKycDoc(scrollPosition)}}/> : null}
            </>
        )
    }
}
const mapDispatchToProps ={
    fetchKycPanDocument
}
export default connect(null,mapDispatchToProps)(KycDoc);
