import React from 'react'
import { render } from 'react-dom';
import axios from 'axios';
import { thisExpression } from '@babel/types';

import { connect } from "react-redux";

class CustomModal extends React.Component {
  
  state ={
    text :"You are about to accept the Kyc order.",
    comment:'',
    isRejecting :false,
    isAccepting:false,
    isLoading:false,
    kycAccepted:false,
    kycRejected:false
  }
  onInputChange = (e) =>{
    console.log(e.target.value)
    this.setState({comment:e.target.value})
  }
componentDidMount(){
  let {rejectReasonRequired,toggleCustomModal} = this.props;
  let {comment} = this.state;
  if(rejectReasonRequired){
    let text="*Reason for rejection";
    // let rejectInput = <div className='comment-input'><input type="text" onChange={this.onInputChange}/></div>
    this.setState({text,isRejecting:true})
  }
}

onProceedClick = () =>{
  let self = this;
  var baseUrl = process.env.REACT_APP_FETCH_URL
  let url = baseUrl+'/twirp/pb.Watson/UpdateDocumentStatus';
  console.log("order id in custom modal :", this.props.orderId)
  if(this.state.comment.trim().length===0){
    alert("enter valid comment");
    return;
  }
  console.log("showing loading screen")
  this.setState({isLoading:true})
  let data ={
    "order_id": this.props.orderId,
    "is_verified": true,
    "verification_comment": `${this.state.comment}`
  }
  if(this.state.isRejecting){
    console.log("reason for rejection is :",this.state.comment)
   
    data ={
      "order_id": this.props.orderId,
      "is_verified": false,
      "verification_comment": `${this.state.comment}`
    }
  }
  console.log("data to proceed is:",data)
  console.log("auth token:",this.props.authToken);
  axios.post(url,data,
    {
        headers: {'Authorization': "bearer " + this.props.authToken}
    }
   )
    .then(({data}) => {
    let {isRejecting} = self.state;  
    let isRejected =  isRejecting ? true :false;
    let isAccepted = !isRejected;
    self.setState({isLoading:false,kycAccepted:isAccepted,kycRejected:isRejected})
    })
  // setTimeout(()=>{
  // let {isRejecting} = self.state;  
  // let isRejected =  isRejecting ? true :false;
  // let isAccepted = !isRejected;
  // self.setState({isLoading:false,kycAccepted:isAccepted,kycRejected:isRejected})
  // },2000)
}

  render(){
    let {rejectReasonRequired,toggleCustomModal,hideKycDoc} = this.props;
    let {text, rejectInput , comment,isLoading,kycAccepted,kycRejected} = this.state;
     console.log(" reason in render is:",comment);
     console.log(kycAccepted,kycRejected);
     let feedbackText = kycAccepted ? 'Kyc doc is successfully accepted ' : "kyc doc is successfully rejected "
  return(
    <div className="custom-modal-container">
    <div className="custom-modal">
    {(!isLoading && kycAccepted ==false && kycRejected ==false )?<> <div className="modal-text">
         {text}
      </div>
      <div className='comment-input'><input type="text" placeholder="Please enter your comment" onChange={this.onInputChange}/></div>
      <div className="modal-buttons">
        <button className="btn-cancel" onClick={toggleCustomModal}>
          Cancel
        </button>
        <button className="btn-proceed" onClick={this.onProceedClick}>
          Proceed
        </button>
    </div></>: (kycAccepted || kycRejected) ? <Feedback text={feedbackText} hideKycDoc={this.props.hideKycDoc}/>:<div >Submitting...</div>}
    </div> 
    </div>
  )
  }
}

const mapStateToProps = (state: any) => {
  return {
    authToken: state.loginState.idToken,
  };
};

export default connect(mapStateToProps)(CustomModal);


const Feedback = ({text,hideKycDoc}) => {
  return(
    <>
    <div>
      {text}
    </div>
   <button className="btn-done" onClick={hideKycDoc}>
           Done
   </button>
   </>
  )
}