import * as React from "react";
import { Table } from "tabler-react";
import moment from "moment";
import TransactionsDialog from "./transactionsDialog";
import editImg from "../../assets/Edit.png";
import deleteImg from "../../assets/Delete.png";

const obj = [
  "Title",
  "Order ID",
  "Category",
  "Product",
  "State",
  "PlayerId",
  "Date Created",
  "Transactions"
];

interface Props {
  data: any;
}

class OrderRows extends React.Component<Props, {}> {
  state = {
    showDetails: false,
    payments: [],
    fullfillments: [],
    refunds: [],
    orderId: ""
  };
  constructor(props) {
    super(props);
  }

  handleClose = () => this.setState({ showDetails: false});

  render() {
    const { data } = this.props;

    var paymentsTable = (
      <Table>
        <Table.Header>
          <Table.ColHeader>Title</Table.ColHeader>
          <Table.ColHeader>Amount</Table.ColHeader>
          <Table.ColHeader>State</Table.ColHeader>
        </Table.Header>
        <Table.Body>
        {
          this.state.payments && this.state.payments.length > 0 ?
          this.state.payments.map((pay, i) => (
            <Table.Row key={i}>
              <Table.Col>{pay.Title}</Table.Col>
              <Table.Col>{pay.Amount}</Table.Col>
              <Table.Col>{pay.State}</Table.Col>
            </Table.Row>
          ))
          :
          <Table.Row><Table.Col>{"No payments"}</Table.Col></Table.Row>
        }
        </Table.Body>
      </Table>
    )

    var fullfillmentsTable = (
      <Table>
        <Table.Header>
          <Table.ColHeader>Type</Table.ColHeader>
          <Table.ColHeader>Amount</Table.ColHeader>
          <Table.ColHeader>Currency</Table.ColHeader>
          <Table.ColHeader>State</Table.ColHeader>
        </Table.Header>
        <Table.Body>
        {
          this.state.fullfillments && this.state.fullfillments.length > 0 ?
          this.state.fullfillments.map((pay, i) => {
            if(pay.Type == "Payment") {
              return(<Table.Row key={i}>
                <Table.Col>{pay.Type}</Table.Col>
                <Table.Col>{pay.Payment.Amount}</Table.Col>
                <Table.Col>{pay.Payment.Currency}</Table.Col>
                <Table.Col>{pay.Payment.State}</Table.Col>
              </Table.Row>)
            }
            else if(pay.Type == ""){
              return null;
            }

          })
          :
          <Table.Row><Table.Col>{"No Fullfillments"}</Table.Col></Table.Row>
        }
        </Table.Body>
      </Table>
    )


    var mainTable = (
      <>
      {
        this.state.showDetails ?
        <div>
          <TransactionsDialog
                  isOpen={this.state.showDetails}
                  handleClose={this.handleClose}
                  payments={this.state.payments}
                  fullfillments={this.state.fullfillments}
                  refunds={this.state.refunds}
                  orderId={this.state.orderId}
          />
        </div>
        :
        <div>
        {
          data && data.length > 0 ?
          <Table>
          <Table.Header>
            {obj.map((o, i) => (
              <Table.ColHeader key={i}>{o}</Table.ColHeader>
            ))}
          </Table.Header>
            <Table.Body>
              {data &&
                data.map((ob, i) => (
                  <Table.Row key={i}>
                    <Table.Col>{`${ob.Title}`}</Table.Col>
                    <Table.Col>{`${ob.id}`}</Table.Col>
                    <Table.Col>{`${ob.Category}`}</Table.Col>
                    <Table.Col>{ob.Product.Type}</Table.Col>
                    <Table.Col>{ob.State}</Table.Col>

                    <Table.Col>
                      {ob.PlayerId}
                    </Table.Col>
                    <Table.Col>
                      {moment.unix(ob.CreatedAt.seconds).format("MMMM Do YYYY, h:mm:ss a")}
                    </Table.Col>
                    <Table.Col>
                      <button onClick={() => {
                        this.setState({showDetails: true, orderId: ob.id})
                      }}>Details</button>
                    </Table.Col>
                  </Table.Row>
                ))
              }
            </Table.Body>
            </Table>
          :
          <div style={{'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
          <h4 style={{'padding': '30px'}}>Please copy a PlayerId in the box above</h4>
          </div>
        }
        </div>
      }

    </>
  )
    //console.log("In rows", data);
    // return (
    //   <div>
    //   {
    //     this.state.showDetails ?
    //     <div>
    //       <button onClick={() => {this.setState({showDetails: false})}}>Close</button>
    //       {paymentsTable}
    //       {fullfillmentsTable}
    //     </div>
    //     :
    //     mainTable
    //   }
    //
    //   </div>
    // );
    return (mainTable);
  }
}

export default OrderRows;
